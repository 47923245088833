<template>
	<div class="item-wrapper" v-if="item">
		<div class="top">
			<div v-if="tag" :class="['tag', {'green': tag.id % 2 === 0}, {'blue': tag.id % 4 === 0}, {'orange': tag.id % 3 === 0}]">
				<span>{{tag.title}}</span>
			</div>
			<div class="preview-container">
				<div class="preview" @click="goToSliderItem(item)" v-if="lazy">
					<img v-if="item.doc_url != null" :data-lazy="imgDomain + item.doc_url" class="img"/>
					<div v-else class="doc-img">
						<img src='./../images/doc.svg' class="img"/>
					</div>
				</div>
				<div class="preview" @click="goToSliderItem(item)" v-else>
					<img v-if="item.doc_url != null" :src="imgDomain + item.doc_url" class="img"/>
					<div v-else class="doc-img">
						<img src='./../images/doc.svg' class="img"/>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom">
			<div class="title" @click="goToSliderItem(item)">{{item.title}}</div>
			<div class="buy">
				<div class="price title">
					<div>Price:</div>
					<span>{{item.price}} <span class="currency">{{currency}}</span></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: ['item', 'sliderIsClickble', 'currency', 'isAuth', 'lazy', 'categories'],
	data: function() {
		return {
			imgDomain: '',
			tag: ''
		}
	},
	watch: {
		categories: function(newVal) {
			console.log(34)
			this.categories = newVal;
			if(this.categories) {
				let tag = this.categories.find(cat => cat.id ==  this.item.category_id);
				this.tag = tag;
			}
		},
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
		if(this.categories) {
			let tag = this.categories.find(cat => cat.id ==  this.item.category_id);
			this.tag = tag;
		}
	},
	methods: {
		openReadyModal() {
			this.$emit('openReadyModal');
		},
		isNumber(variable) {
			return Number.isFinite(variable) && typeof variable === "number";
		},
		goToSliderItem(item) {
			let self = this;
			setTimeout(()=> {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
		getPack() {
			if (this.isAuth) {
				this.openReadyModal();
			} else {
				this.openSignInModal();
			}
		},
		openSignInModal() {
			this.$emit('openSignInModal');
		},
		openBuyModal(item, type) {
			this.$emit('openBuyModal', item, type)
		}
	},
}
</script>
